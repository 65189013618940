import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img
          src="https://media.giphy.com/media/TfRuiiBj3mbHZuK05H/giphy.gif"
          alt="cat"
        />
      </header>
    </div>
  );
}

export default App;
